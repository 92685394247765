<style lang="less" scoped>
.width-s {
width: 400px !important;
}
.content-item {
  background: #fff;
  padding: 0 30px;
  border-radius: 2px;

  .content-item-title {
    padding: 15px 0;
    font-weight: bold;
    border-bottom: 1px solid #e8e8e8;
  }

  .content-item-main {
    padding: 14px 0;

    .item-main-col {
      display: flex;

      .item-main {
        width: 33.3%;
        padding: 10px 0;

        .item-main-avatarUrl {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
      }
    }
  }
}

.member-btn {
  padding: 0 0 10px 50px;
}

.p-l-50 {
  padding-left: 50px;
}
</style>
<template>
  <div class="page-main">
    <div class="page-content">
      <div class="page-content-item">
        <div style="padding-top: 36px"></div>
        <Form ref="form" :model="formData" :rules="formRule" :label-width="150">
          <div class="content-item">
            <div class="content-item-title">基本信息</div>
            <div class="content-item-main">
              <Row>
                <Col :span="8">
                  <FormItem label="选择模板" prop="pfModelCode">
                    <Select
                      class="width-xs"
                      v-model="formData.pfModelCode"
                      placeholder="请选择"
                      @on-change="handleModelChange"
                      :disabled="allDisabled || editDisabled"
                      filterable
                    >
                      <Option
                        v-for="(item, key) in modelList"
                        :value="item.pfModelCode"
                        :key="key"
                        >{{ item.templateName }}
                      </Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="14">
                  <FormItem label="消息标题" prop="messageTitle">
                    <Input
                      v-model="formData.messageTitle"
                      placeholder=""
                      maxlength="20"
                      show-word-limit
                      :disabled="true"
                    >
                    </Input>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="14">
                  <FormItem label="模板内容" prop="context">
                    <Input
                      v-model="formData.context"
                      placeholder=""
                      type="textarea"
                      maxlength="500"
                      show-word-limit
                      :disabled="
                        (allDisabled || editDisabled) &&
                        !(
                          formData.triggerWay == 0 &&
                          formData.channelType == 'WECHAT'
                        )
                      "
                      :rows="9"
                    >
                    </Input>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="8">
                  <FormItem label="推送对象" prop="sendObjectType">
                    <Select
                      class="width-xs"
                      v-model="formData.sendObjectType"
                      placeholder="请选择"
                      :disabled="allDisabled || editDisabled"
                    >
                      <Option
                        v-for="(item, key) in ['所有用户']"
                        :value="key + ''"
                        :key="key"
                        >{{ item }}</Option
                      >
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="18">
                  <FormItem label="支持推送渠道" prop="channelType">
                    <Radio-group v-model="formData.channelType">
                      <!--发送方式：短信SMS,微信WECHAT,站内信INNM,云推送APP,邮箱MAIL,小程序H5-->
                      <Radio
                        :key="key"
                        :label="item.value"
                        :disabled="true"
                        v-for="(item, key) in MESSAGE_CHANNEL_TYPE"
                        >{{ item.label }}</Radio
                      >
                    </Radio-group>
                    <!-- <Radio-group v-model="formData.merchantScope">
                          <Radio :label="0" :disabled="allDisabled">站内信</Radio>
                          <Radio :label="1" :disabled="allDisabled">短信</Radio>
                          <Radio :label="0" :disabled="allDisabled">小程序消息</Radio>
                          <Radio :label="1" :disabled="allDisabled">公众号消息</Radio>
                        </Radio-group>-->
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="12">
                  <FormItem label="推送时间" prop="time">
                    <DatePicker
                      type="datetimerange"
                      placeholder="选择时间"
                      class="width-s"
                      v-model="formData.time"
                      format="yyyy-MM-dd HH:mm:ss"
                      transfer
                    ></DatePicker> </FormItem
                ></Col>
                <!--  至<Col :span="6"><FormItem label="" prop="sendDateEnd">
                  <DatePicker type="date" placeholder="选择结束时间" class="width-s" v-model="formData.sendDateEnd"
                    format="yyyy-MM-dd"></DatePicker>
                </FormItem>
                </Col>-->
              </Row>
            </div>
          </div>
        </Form>
      </div>

      <sp-footer>
        <Button
          v-if="pageType === 'add' || pageType === 'edit'"
          type="primary"
          @click="submit"
          :loading="btnLoading"
          >保存</Button
        >
        <Button @click="cancel" style="margin-left: 10px">返回</Button>
      </sp-footer>
    </div>
  </div>
</template>
<script>
  import {
    MESSAGE_CHANNEL_TYPE,
  //   // MEMBER_STATUS,
  } from '@/util/enum';
  export default {
    data() {
      // var _this = this;
      return {
        btnLoading: false,
        // EVENT_TYPE_TASK: ['活动消息', '系统公告'],//'积分消息','订单消息','会员消息',
        linkOptions: ['miniPage', 'otherMiniPage'],
        MESSAGE_CHANNEL_TYPE,
        formRule: {
          sendObjectType: [
            {
              required: true,
              message: '请选择',
              trigger: 'blur',
            },
          ],
          time: [
            {
              required: true,
              message: '请选择推送时间',
              trigger: 'change',
              type: 'array',
              validator: (rule, val, cb) => {
                let flag = val && val.length && val.every((v) => v);
                if (!flag) {
                  cb(new Error('请选择推送时间'));
                  return;
                }
                cb();
              },
            },
          ],
          sendDateEnd: [
            {
              required: true,
              type: 'date',
              message: '请选择结束时间',
              trigger: 'change',
            },
          ],

          pfModelCode: [
            {
              required: true,
              message: '请选择模板',
              trigger: 'blur',
            },
          ],
        },
        formData: {
          triggerWay: 0, // 触发方式 0 自动 1手动
          // eventType: '0', //任务类型
          pfModelCode: '', // ID
          sendObjectType: '0',
          messageTitle: '', // 任务名称
          context: '',
          time: [],
        // /"sendDateEnd": "",
        // "sendDateBegin": '',
        },
        modelList: [],
        triggerMode: 1,
      };
    },
    created() {
      // this.formData.triggerMode = this.triggerMode
      this.getApprovedModelList(() => {
        if (this.pfMessageConfigCode) {
          this.getDetail();
        }
      });
    },
    computed: {
      /* EVENT_TYPE() {
     if(this.triggerMode === 1) {
       return [EVNET_TYPE_TASK[EVNET_TYPE_TASK.length - 1]]
     } else {
       return EVENT_TYPE
     }
   },
  triggerMode() {
     if (this.$route.name === 'pmtEventAutoAdd' || this.$route.name === 'pmtEventAutoEdit' || this.$route.name === 'pmtEventAutoDetail') {
       return 0
     } else {
       return 1
     }
   }, */
      editDisabled() {
        // const data = this.formData
        return this.pageType === 'edit';
      },
      allDisabled() {
        return this.pageType === 'detail';
      },
      pageType() {
        let type = 'add';
        // eslint-disable-next-line eqeqeq
        if (this.$route.name == 'messageCentermanageDetail') {
          type = 'detail';
        }
        if (
          // eslint-disable-next-line eqeqeq
          this.$route.name == 'messageCentermanageAdd' &&
          !this.$route.query.pfMessageConfigCode
        ) {
          type = 'add';
          // eslint-disable-next-line eqeqeq
        } else if (this.$route.name == 'messageCentermanageEdit') {
          type = 'edit';
        }
        return type;
      },
      pfMessageConfigCode() {
        return this.$route.query.pfMessageConfigCode;
      },
    },
    methods: {
      /**
       * 获取自动任务数据
       */
      getDetail(cb) {
        let data = { pfMessageConfigCode: this.pfMessageConfigCode };
        this.$axios({
          url: this.$api.message.getMessageConfig,
          data,
        }).then((d) => {
          d.time = [d.sendDateBegin, d.sendDateEnd]; // 事件有效期]
          delete d.sendDateBegin;
          delete d.sendDateEnd;
          this.formData = d;
          cb && cb();
        });
      },
      /**
       * 监听模板变化
       */
      handleModelChange(val) {
        console.log(val);
        let arr = this.modelList;

        let item = arr.filter((v, index) => {
          // eslint-disable-next-line eqeqeq
          return v.pfModelCode == val;
        });
        if (item && item.length > 0) {
          this.formData.messageTitle = item[0].templateName;
          this.formData.context = item[0].context || '';
          this.formData.channelType = item[0].channelType || '';
          this.formData.businessType = item[0].businessType || '';
        }
      },
      /**
       * 获取数据
       */
      getApprovedModelList(cb) {
        let data = {
          pageNum: 1,
          pageSize: 999,
        };
        this.$axios({
          url: this.$api.message.getApprovedModelList,
          data,
        }).then((data) => {
          this.modelList = data || [];
          cb && cb();
        // this.pager.total = data.total || 0;
        });
      },
      /**
       * 保存
       */
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.saveMessageConfig();
          }
        });
      },
      saveMessageConfig() {
        let params = Object.assign({}, this.formData, {
          // posttype:'1',
          sendDateBegin: this.formatDate(
            this.formData.time[0],
            'yyyy-MM-dd HH:mm:ss'
          ),
          sendDateEnd: this.formatDate(
            new Date(this.formData.time[1]).getTime(),
            'yyyy-MM-dd HH:mm:ss'
          ),
        });
        delete params.time;
        this.$axios({
          url: this.$api.message.saveMessageConfig,
          data: params,
        }).then(() => {
          this.notice({
            type: 'success',
            desc: '保存成功',
          });
          this.cancel();
        });
      },
      /**
       * 返回按钮
       */
      cancel() {
        this.$router.replace({
          name: 'messageCentermanage',
        });
      },
    },
  };
</script>